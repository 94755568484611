@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ZenDots";
  src: url(./fonts/ZenDots.ttf);
}

@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-VariableFont_wght.ttf);
}

body {
  background-size: cover;
  min-height: 100vh;
}
