.grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: 5px 1fr 1fr;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat-VariableFont_wght.ttf);
}

input::placeholder {
  color: black;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.venture {
  /* font-size: 1.2rem; */
}

@media (max-width: 768px) {
  .venture {
    font-size: 0.7rem;
  }
}
