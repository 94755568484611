@font-face {
  font-family: "Zen Dots";
  src: url(../fonts/ZenDots.ttf);
}

.bg-container {
  background-image: url(../assets/bg_desktop.gif);
}
.quark-logo {
  background: radial-gradient(
    ellipse at center,
    rgb(0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 70%
  );
}
.embark {
  animation-delay: 150ms;
  transition: ease-out;
  animation-duration: 800ms;
  color: rgba(243, 237, 180, 1);
  animation: glow 2s infinite;
}

@keyframes glow {
  0% {
    text-shadow: px 0 5px rgba(243, 237, 180, 1);
  }
  50% {
    text-shadow: 0px 0px 25px white;
  }
  100% {
    text-shadow: px 0px 5px rgba(243, 237, 180, 1);
  }
}

.devsoc {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 1.65vh;
  width: 100%;
  padding: 0.05vh;
  background-color: rgba(16, 6, 61, 1);
  color: aliceblue;
}
